import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SquareImageComponent } from '~/components';
import {
  InfiniteScrollDropdownProps,
  InfiniteScrollDropdownSelection,
} from '~/components/InfiniteScrollDropdown/InfiniteScrollDropdown';
import { OrganizationPageQueryOrganization } from '~/constants/graphqlTypes';
import { OrganizationType, useOrganizationsPageQuery } from '~/types/api.graphql';
import { getOrganizationInitials } from '~/utils/organization';

import { DropdownItemProps } from '../Dropdown/Dropdown';
import { StyledInfiniteScrollDropdown } from './OrganizationsDropdown.styles';

const PAGE_ITEMS_COUNT = 10;

export interface OrganizationsDropdownProps
  extends Omit<InfiniteScrollDropdownProps<OrganizationPageQueryOrganization>, 'onSelectionChange'> {
  organizationType?: OrganizationType;
  isOrganizationDisabled?: (org: OrganizationPageQueryOrganization) => boolean;
  onSelectionChange?: (
    selection: InfiniteScrollDropdownSelection,
    organization: OrganizationPageQueryOrganization,
  ) => void;
}

const OrganizationsDropdown = (props: OrganizationsDropdownProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<DropdownItemProps<OrganizationPageQueryOrganization>[]>([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchOrgInfiniteScroll, setSearchOrgInfiniteScroll] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>(props.selectedIndex);

  const disabledKeys = useMemo(() => {
    return items.filter((item) => item.disabled).map((item) => item.key);
  }, [items]);

  const { refetch, loading: loadingFirst } = useOrganizationsPageQuery({
    variables: {
      pageNumber: page,
      pageItemsCount: PAGE_ITEMS_COUNT,
      searchQuery: searchOrgInfiniteScroll,
      organizationType: props.organizationType,
    },
    fetchPolicy: 'network-only',
    skip: page > 1,
    onCompleted: (dataOrgs) => {
      const newItems = [
        ...items,
        ...(dataOrgs?.organizationsPage?.nodes?.map((organization) => ({
          text: organization.name,
          imgSrc: organization.imageUrl,
          value: organization,
          description: t(`organization.settings.orgTypes.${organization?.organizationType?.toLowerCase()}`),
          key: organization.id,
          disabled: props.isOrganizationDisabled ? props.isOrganizationDisabled(organization) : false,
          icon: (
            <SquareImageComponent
              src={organization.imageUrl}
              squared
              text={getOrganizationInitials(organization.name || '')}
            />
          ),
        })) || []),
      ];
      setItems(newItems);
      setHasMore(dataOrgs?.organizationsPage?.pageInfo?.hasNextPage);
      setPage(page + 1);
    },
  });

  useEffect(() => {
    setPage(1);
    setItems([]);
    setSelectedIndex(props.selectedIndex);
  }, [searchOrgInfiniteScroll, props.organizationType]);

  useEffect(() => {
    if (items?.length === 1 && !items?.[0]?.disabled) {
      handleItemSelection(0);
    }
  }, [items, page]);

  const handleLoadMore = () => {
    void (async () => {
      setLoadingMore(true);
      await refetch({ pageNumber: page, pageItemsCount: PAGE_ITEMS_COUNT, searchQuery: searchOrgInfiniteScroll });
      setPage(page + 1);
      setLoadingMore(false);
    })();
  };

  const handleItemSelection = (selection) => {
    const selectedIndex = selection as number;
    setSelectedIndex(selectedIndex);
    props.onSelectionChange?.(selectedIndex, items[selectedIndex].value);
  };

  return (
    <StyledInfiniteScrollDropdown
      {...props}
      items={items}
      disabledKeys={disabledKeys}
      onSelectionChange={handleItemSelection}
      selectedIndex={selectedIndex}
      setSearchOrgInfiniteScroll={setSearchOrgInfiniteScroll}
      searchOrgInfiniteScroll={searchOrgInfiniteScroll}
      hasMore={hasMore}
      loadMore={handleLoadMore}
      loadingFirst={loadingFirst}
      loadingMore={loadingMore}
      disallowEmptySelection
    />
  );
};

export default OrganizationsDropdown;

import { DateRange } from 'moment-range';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '../../assets';
import { FilterButton } from '../../overwrites/components/DateFilterButton/FilterButton.styles';
import { Modal } from '../';
import { ModalProps } from '../Modal/Modal';
import { ButtonText, StyledBadge, StyledDateRangePicker } from './DateFilterButton.styles';

interface DateFilterButtonProps {
  modalTitle?: string;
  dateRange?: DateRange;
  maximumDate?: Date;
  minDate?: Date;
  maxDaysCount?: number;
  breakpointShowButtonText?: string;
  onRangeSelect?: (range: DateRange) => void;
  onReset?: () => void;
  disabled?: boolean;
}

const DateFilterButton = ({
  modalTitle,
  dateRange,
  maximumDate,
  minDate,
  maxDaysCount,
  breakpointShowButtonText,
  onRangeSelect,
  onReset,
  disabled,
  ...rest
}: DateFilterButtonProps) => {
  const { t } = useTranslation();
  const dateRangeFilterRef = React.useRef(null);

  interface DateRangeModalProps extends Omit<ModalProps, 'children' | 'open' | 'onClose'> {
    selectedRange?: DateRange;
    onRangeSubmit: (range: DateRange) => void;
  }

  const DateRangeModal = forwardRef(({ selectedRange, onRangeSubmit, ...rest }: DateRangeModalProps, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

    const [dateRange, setDateRange] = useState<DateRange>(selectedRange);

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true);
      },
    }));

    const handleClose = () => {
      setOpen(false);
    };

    const handleSubmit = () => {
      onRangeSubmit(dateRange);
      setOpen(false);
    };

    const handleDateChange = (dateRange: DateRange) => {
      setDateRange(dateRange);
    };

    return (
      <Modal
        open={isOpen}
        title={modalTitle || t('common.filterBy')}
        width="48rem"
        submitButtonTitle={t('common.apply')}
        cancelButtonTitle={t('components.dateRangePicker.clearFilter')}
        onClose={handleClose}
        onCancel={onReset}
        onSubmit={handleSubmit}
        disabledSubmitButton={disabledSubmitButton}
        {...rest}
      >
        <StyledDateRangePicker
          selectedRange={dateRange}
          maxDaysCount={maxDaysCount}
          minDate={minDate}
          maximumDate={maximumDate}
          onChange={handleDateChange}
          setIsWrongRange={setDisabledSubmitButton}
        />
      </Modal>
    );
  });

  const selectedRangeText = dateRange
    ? `${t('dateShort', { val: dateRange.start })} - ${t('dateShort', { val: dateRange.end })}`
    : t('components.dateRangePicker.selectRange');
  return (
    <>
      <FilterButton onClick={() => dateRangeFilterRef.current.open()} {...rest} disabled={disabled}>
        <CalendarIcon />
        <ButtonText breakpoint={breakpointShowButtonText}>{selectedRangeText}</ButtonText>
        {dateRange && <StyledBadge breakpoint={breakpointShowButtonText}>1</StyledBadge>}
      </FilterButton>
      <DateRangeModal ref={dateRangeFilterRef} selectedRange={dateRange} onRangeSubmit={onRangeSelect} />
    </>
  );
};

export default DateFilterButton;
